@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';
.select-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  background-color: white;
  color: $textColor;
  padding: 1rem 1.25rem;
  border: 1px solid rgb(217 217 217);
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1;
}

.select-trigger[data-placeholder] {
  color: $textColor;
}

.select-icon {
  color: $textColor;
  margin-left: 10px;
  font-size: 16px;
}
.select-content {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 1002;
}

.select-viewport {
  padding: 5px;
}

.select-item {
  font-size: 15px;
  line-height: 1;
  color: $textColor;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  cursor: pointer;
}
.select-item[data-disabled] {
  color: #8a8a8a;
  pointer-events: none;
}
.select-item[data-highlighted] {
  outline: none;
  background-color: $primaryColor;
  color: #fff;
}

.select-scroll-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: $textColor;
  cursor: default;
}
