.rate {
  @apply h-4 text-primary whitespace-nowrap;

  svg {
    @apply h-4 w-4;
  }

  :global {
    .ant-rate-star {
      @apply h-4 mr-0;
    }

    .anticon-star {
      vertical-align: top;
    }
  }
}
